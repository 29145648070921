exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */)
}

